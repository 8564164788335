import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

function Document(props) {
    return(
        <details>
            <summary>
                Quản lý hồ sơ thiết kế
                <img
                    className="position-absolute icon-dropdow right-position"
                    src={require("../../../../assets/icon/arrow-down.png")}
                    alt="arrow-down"
                />
                <img
                    className="position-absolute icon-dropright right-position"
                    src={require("../../../../assets/icon/arrow-right.png")}
                    alt="arrow-right"
                />
            </summary>
            <Link
                className="records-link d-block position-relative"
                to="/quan-ly-tai-lieu/thai-nguyen"
            >
                Hồ sơ thiết kế <span className="position-absolute">→</span>
            </Link>
            <Link
                className="records-link d-block position-relative"
                to="/quan-ly-tai-lieu/thai-nguyen"
            >
                Văn bản pháp lý<span className="position-absolute">→</span>
            </Link>
        </details>
    );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Document);