import React from "react";
import HeaderView from "../header/header.view.jsx";
import FooterView from "../footer/footer.view.jsx";
import LoadingWithApiQueue from "../loading/loading-with-queue.view";
import TopView from "../top/top.view.jsx";
import "./layout.scss";

export default class LayoutDesktopView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyHeight: null,
    };
  }

  componentDidMount() {
    const FooterContainerHeight = document.getElementsByClassName(
      "footer-container"
    )[0].offsetHeight;
    const HeaderContainerHeight = document.getElementsByClassName(
      "header-container"
    )[0].offsetHeight;

    this.setState({
      bodyHeight: {
        minHeight: `${
          window.innerHeight -
          FooterContainerHeight -
          HeaderContainerHeight -
          10
        }px`,
      },
    });
  }

  render() {
    return (
      <div className="layout-container">
        <HeaderView match={this.props.match} />
        <div style={this.state.bodyHeight}>{this.props.children}</div>
        <FooterView />
        <LoadingWithApiQueue />
      </div>
    );
  }
}
